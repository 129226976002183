//引入vuex
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
//引入子仓库
import LoginDatas from "@/store/UserDatas/LoginDatas";
import CarouselData from "@/store/AppData/CarouselData";
import NewsData from "@/store/AppData/NewsData";
export default new Vuex.Store({
    modules:{
        LoginDatas,CarouselData,NewsData,

    }
})

