import Vue from 'vue';
import App from './App.vue';
//消息订阅与发布库
import pubsub from 'pubsub-js';
//路由库&&路由文件
import VueRouter from "vue-router";
import router from "./router";
//vueX
import store from './store';
//axios
import axios from 'axios';

//ElementUI库
import ElementUI from "element-ui";

//bootstrap5
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NetWorkProcess from "@/ToolAPI/NetworkProcess";


import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/languages/zh_cn.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import VueFroala from 'vue-froala-wysiwyg'

Vue.use(VueFroala)

Vue.config.productionTip = false;
//统一引入
Vue.use(pubsub);
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$NetworkProcess = NetWorkProcess;
//UI按需引入
// Vue.use(UI组件对象);
new Vue({
  render: h => h(App),
  router,//路由
  store,
  beforeCreate(){
    Vue.prototype.$bus = this;//全局事件总线
  },
}).$mount('#app');
