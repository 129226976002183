<template>
    <div id="bigID">
        <div id="sideBar">
            <el-menu
                    mode="vertical"
                    default-active="0"
                    class="el-menu-vertical-demo el-menu"
                    background-color="#001f3f"
                    text-color="#f8f9fa"
                    active-text-color="#0074D9"
            >
                <!--            <el-menu-item index="0">-->
<!--                <el-image :src="logoUrl" style="width: 180px;height: 100px"></el-image>-->
              <h3 style="text-align: center;line-height: 50px;font-weight: bold">后台管理:{{username}}</h3>
                <!--            </el-menu-item>-->
                <el-menu-item index="0" @click="toCarouselManage"><!--======================================-->
                    <i class="el-icon-s-order"></i>
                    <span>轮播图管理</span>
                </el-menu-item>
                <el-menu-item index="1" @click="toNewsManage"><!--======================================-->
                    <i class="el-icon-s-order"></i>
                    <span>新闻内容管理</span>
                </el-menu-item>
                <el-menu-item index="2" @click="toDataView"><!--======================================-->
                    <i class="el-icon-s-order"></i>
                    <span>可视化数据</span>
                </el-menu-item>
                <el-menu-item index="3" @click="toNoticeManage"><!--======================================-->
                    <i class="el-icon-s-order"></i>
                    <span>公告发布</span>
                </el-menu-item>
            </el-menu>
        </div>
        <div id="dataArea">
          <transition name="el-fade-in-linear" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
    </div>
</template>

<script>
import router from "@/router";
// import ToolBar from "@/components/Header/ToolBar.vue";

export default {
  name: "MainPage",
  components: {},
  mounted() {
    // this.toCarouselManage();
  },
  data(){
    return {
      username:sessionStorage.getItem("username"),
      // navContent:"轮播图管理",
    }
  },
  methods:{

    toCarouselManage(){
      // this.navContent = "轮播图管理";
      router.replace({
        name:"CarouselManage"
      }).catch(err=>err);
    },

    toNewsManage(){
      // this.navContent = "新闻管理";
      router.replace({
        name:"NewsManage"
      }).catch(err=>err);
    },

    toDataView(){
      // this.navContent = "网站数据监测";
      router.replace({
        name:"DataDetection"
      }).catch(err=>err);
    },

    toNoticeManage(){
      // this.navContent = "公告栏管理";
      router.replace({
        name:"NoticeManage"
      }).catch(err=>err);
    }
  },

}
</script>

<style scoped lang="scss">
    #bigID{
        width: 100%;
        height: 100%;
        display: flex;
        >#sideBar{
            width: 230px;
            height: 100%;
          color: white;
        }
        >#dataArea{
            width: 100%;
            height: 100%;
        }
    }
    .el-menu{
        width: 230px;
        height: 100%;
        border: 0;
    }
</style>