import NetworkProcess from "@/ToolAPI/NetworkProcess";
import router from "@/router";
import {Message} from "element-ui";
export default {
    namespaced:true,
    actions:{
        InsertNews(context,formData){
            NetworkProcess.Request_Post("/NewsInsert",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }
                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    });
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"新增失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"添加成功!",
                        type:"success",
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryNewsTitle","");
                    formData.append("currentPage",1);
                    context.dispatch("NewsQuery",formData);
                }
            })


        },
        DeleteNews(context,formData){
            NetworkProcess.Request_Post("/NewsDelete",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }
                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    });
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"删除失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"删除成功!"+res.data.dataDesc,
                        type:"success",
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryNewsTitle","");
                    formData.append("currentPage",1);
                    context.dispatch("NewsQuery",formData);
                }
            })
        },

        AlterNews(context,formData){
            NetworkProcess.Request_Post("/NewsAlter",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }

                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    });
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"修改失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"修改成功!"+res.data.dataDesc,
                        type:"success",
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryNewsTitle","");
                    formData.append("currentPage",1);
                    context.dispatch("NewsQuery",formData);
                }
            })


        },

        NewsQuery (context,formData){
            NetworkProcess.Request_Post("/NewsQuery",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            }, (res)=> {
                if(res.data.statusCode === 301){
                    router.replace({
                        name: "LoginPage"
                    });
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"查询失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    context.commit("UpdateNewsData",res.data.data)
                    context.commit("UpdateNewsDataCount",res.data.dataTot)
                }
            })


        }




    },
    mutations:{
        UpdateNewsData(state,value){
            state.NewsData = value;
        },
        UpdateNewsDataCount(state,value){
            state.NewsDataCount = value;
        }


    },
    state:{
        NewsData:[],
        NewsDataCount:0,
        pageSize:8,
    }
}
