/**
*   author:胡作非为还响桃
*   Date:2024/7/30
*   FunctionDesc:""
*/
<template>
  <div id="NoticeManageRoot">
    <ToolBar>
      <template v-slot:Tool1>
        <h3 style="text-align: center;line-height: 50px;font-weight: bold">公告管理</h3>
      </template>
    </ToolBar>
  </div>
</template>

<script>
import ToolBar from "@/components/Header/ToolBar.vue";

export default {
  name: "NoticeManage",
  components: {ToolBar}
}
</script>

<style scoped lang="scss">
  #NoticeManageRoot{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

  }
</style>