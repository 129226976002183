/**
*   author:胡作非为还响桃
*   Date:2024/7/30
*   FunctionDesc:""
*/
<template>
  <div id="CarouselManageRoot">
    <ToolBar>
      <template v-slot:Tool1>
        <h3 style="text-align: center;line-height: 50px;font-weight: bold">轮播图管理</h3>
      </template>
    </ToolBar>
    <div class="d-flex">
      <form id="ImageSearch" onsubmit="" class="row">
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="请输入图片名称..." v-model="queryImageName"/>
            <button class="btn btn-primary" @click="SearchConfirm">搜索</button>
          </div>
        </div>
      </form>
      <div class="ToolBarItems d-flex">
        <el-tooltip class="item" effect="dark" content="页面仅展示最新的5张图片，请选择合适的图片权重。" placement="top-start">
          <el-button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#AddCarouselModal">新增轮播图</el-button>
        </el-tooltip>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="AddCarouselModal" tabindex="-1" aria-labelledby="AddCarouselModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="AddCarouselModalLabel">新增轮播图</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form id="CarouselAdd">
                <div>
                  <label class="form-label">轮播图名称:</label>
                  <input class="form-control" v-model="CarouselInsertData.CarouselName" type="text" placeholder="请输入轮播图名称..."/>
                </div>
                <div>
                  <label class="form-label">图片权重:</label>
                  <input id="range1" v-model="CarouselInsertData.CarouselPriority" class="form-range" type="range" step="1" min="0" max="10">
                </div>
                <div>
                  <label class="form-label">图片文件:</label>
                  <input id="carousel1" class="form-control" type="file" placeholder="请上传图片..."/>
                </div>

              </form>
            </div>
            <div class="modal-footer">
              <button type="button" @click="InsertCancel" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="button" @click="InsertConfirm" class="btn btn-primary">确定</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-table
        :data="CarouselData"
        style="width: 100%">
      <el-table-column
          fixed
          prop="id"
          label="id"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="carousel_name"
          label="轮播图名称"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="carousel_path"
          label="图片文件地址"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="carousel_url"
          label="访问URL"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="carousel_priority"
          label="展示优先级"
          width="auto">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="170">
        <template slot-scope="scope">
          <el-button
              @click="LoadSelectedCarousel(scope.$index)"
              type="text"
              size="small"
              data-bs-toggle="modal" data-bs-target="#AlterCarouselModal">
            修改
          </el-button>

          <el-button
              @click="deleteCarousel(scope.$index)"
              type="text"
              size="small">
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- Modal -->
    <div class="modal fade" id="AlterCarouselModal" tabindex="-1" aria-labelledby="AlterCarouselModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="AlterCarouselModalLabel">修改轮播图信息</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form id="CarouselAdd">
              <div >
                <label class="form-label">id:</label>
                <input class="form-control" v-model="SelectedCarouselData.id" type="text" disabled="disabled"/>
              </div>
              <div>
                <label class="form-label">轮播图名称:</label>
                <input class="form-control" v-model="SelectedCarouselData.carousel_name" type="text" placeholder="请输入轮播图名称..."/>
              </div>
              <div>
                <label class="form-label">图片权重:</label>
                <input id="range1" class="form-range" v-model="SelectedCarouselData.carousel_priority" type="range" step="1" min="0" max="10">
              </div>
              <div>
                <label class="form-label">更改图片文件:</label>
                <input id="carousel2" class="form-control" type="file" placeholder="请上传图片..."/>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" @click="AlterCancel" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" @click="AlterConfirm" class="btn btn-primary">确定修改</button>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
        class="text-center"
        background
        layout="prev, pager, next"
        @prev-click="previousPage"
        @next-click="nextPage"
        @current-change="changePage"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="CarouselDataCount">
    </el-pagination>


  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import ToolBar from "@/components/Header/ToolBar.vue";

export default {
  name: "CarouselManage",
  components: {ToolBar},
  mounted() {
    this.CarouselDataQuery();
  },
  computed:{
    ...mapState("CarouselData",['CarouselData','pageSize','CarouselDataCount']),

  },
  data(){
    return {
      queryImageName:"",
      currentPage:1,
      CarouselInsertData:{
        CarouselName:"",
        CarouselPriority:0,
      },
      SelectedCarouselData:{
        id:"",
        carousel_name:"",
        carousel_path:"",
        carousel_url:"",
        carousel_priority:"",
      }
    }
  },
  methods:{
    previousPage(){
      this.currentPage--;
      this.CarouselDataQuery();
    },
    nextPage(){
      this.currentPage++;
      this.CarouselDataQuery();
    },
    changePage(page){
      if(page !== undefined){
        this.currentPage = page;
      }
      this.CarouselDataQuery();
    },
    SearchConfirm(){
      this.CarouselDataQuery();
    },
    CarouselDataQuery(){
      //页面修改时发起下一次查询请求...
      let formData = new FormData();
      formData.append("currentPage",this.currentPage);
      formData.append("pageSize",this.pageSize);
      formData.append("queryImageName",this.queryImageName);
      this.CarouselQuery(formData);
    },



    //增删改查
    LoadSelectedCarousel(index){
      this.SelectedCarouselData.id = this.CarouselData[index]['id'];
      this.SelectedCarouselData.carousel_name = this.CarouselData[index]['carousel_name'];
      this.SelectedCarouselData.carousel_path = this.CarouselData[index]['carousel_path'];
      this.SelectedCarouselData.carousel_url = this.CarouselData[index]['carousel_url'];
      this.SelectedCarouselData.carousel_priority = this.CarouselData[index]['carousel_priority'];
      // console.log(this.SelectedCarouselData)
    },


    InsertConfirm(){
      let formData = new FormData();
      formData.append("carousel_name",this.CarouselInsertData.CarouselName);
      formData.append("carousel_priority",this.CarouselInsertData.CarouselPriority);
      let fileObj = document.getElementById('carousel1');//获取文件上传的那个input框
      const file = fileObj.files[0];//获取文件对象
      formData.append("carousel_file",file);
      this.UploadCarousel(formData);
      // formData.forEach((item,index)=>{
      //   formData.delete(index);
      // })
    },

    InsertCancel(){
      this.CarouselInsertData.CarouselName = "";
      this.CarouselInsertData.CarouselPriority = "";
    },

    AlterConfirm(){
      let formData = new FormData();
      formData.append("id",this.SelectedCarouselData.id);
      formData.append("carousel_name",this.SelectedCarouselData.carousel_name);
      formData.append("carousel_priority",this.SelectedCarouselData.carousel_priority);
      let fileObj = document.getElementById('carousel2');
      const file = fileObj.files[0];
      formData.append("carousel_file",file);
      this.AlterCarousel(formData);
    },
    AlterCancel(){
      this.SelectedCarouselData.id = "";
      this.SelectedCarouselData.carousel_name = "";
      this.SelectedCarouselData.carousel_path = "";
      this.SelectedCarouselData.carousel_url = "";
      this.SelectedCarouselData.carousel_priority = "";
    },


    deleteCarousel(index){
      let formData = new FormData();
      formData.append("id",this.CarouselData[index]['id']);
      this.DeleteCarousel(formData);
    },



    ...mapActions("CarouselData",['UploadCarousel','DeleteCarousel','AlterCarousel','CarouselQuery']),
  },
  watch:{
    "queryImageName":{
      deep:false,
      immediate:false,
      handler(newData){
        if(newData === ""){
          this.CarouselDataQuery();
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">
  #CarouselManageRoot{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    font-weight: bold;
  }
  #ImageSearch{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
  }
  .ToolBarItems{
    width:500px;
    height: 100%;
    margin: 0;
    padding: 10px;
  }
</style>