import NetworkProcess from "@/ToolAPI/NetworkProcess";
import router from "@/router";
import {Message} from "element-ui";

export default {
    namespaced:true,
    actions:{
        //新增
        UploadCarousel(context,formData){
            NetworkProcess.Request_Post("/CarouselInsert",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }
                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    });
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"新增失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"新增成功!",
                        type:"success"
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryImageName","");
                    formData.append("currentPage",1);
                    context.dispatch("CarouselQuery",formData);
                }
            })
        },
        //删除
        DeleteCarousel(context,formData){
            NetworkProcess.Request_Post("/CarouselDelete",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }
                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    })
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"删除失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"删除成功!",
                        type:"success"
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryImageName","");
                    formData.append("currentPage",1);
                    context.dispatch("CarouselQuery",formData);
                }

            })
        },
        //修改
        AlterCarousel(context,formData){
            NetworkProcess.Request_Post("/CarouselAlter",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                }),
                'Content-type' : 'multipart/form-data;charset=UTF-8'
            },(res)=>{
                if(res.headers.authorization !== undefined){
                    // console.log("access已更新"+JSON.parse(res.headers.authorization).AccessToken)
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                }
                if(res.data.statusCode === 302){
                    router.replace({
                        name:"LoginPage"
                    })
                }else if(res.data.statusCode === 201){
                    Message({
                        message:"修改失败!"+res.data.dataDesc,
                        type:"warning"
                    });
                }else{
                    Message({
                        message:"修改成功!",
                        type:"success"
                    });
                    let formData = new FormData();
                    formData.append("pageSize",context.state.pageSize);
                    formData.append("queryImageName","");
                    formData.append("currentPage",1);
                    context.dispatch("CarouselQuery",formData);
                }

            })
        },
        //查询
        CarouselQuery(context,formData){
            NetworkProcess.Request_Post("/CarouselQuery",formData,{
                'Authorization':JSON.stringify({
                    "user_id":sessionStorage.getItem("user_id"),
                    "RefreshToken":sessionStorage.getItem("RefreshToken"),
                    "AccessToken":sessionStorage.getItem("AccessToken"),
                })
            },(res)=>{
                if(res.data.statusCode === 301){
                    router.replace({
                        name:"LoginPage"
                    })
                }else{
                    context.commit("CarouselDataUpdate",res.data.data);
                    context.commit("CrouselDataCountUpdate",res.data.dataTot);
                }
            })
        }




    },
    mutations:{
        CarouselDataUpdate(state,value){
            state.CarouselData = value;
        },
        CrouselDataCountUpdate(state,value){
            state.CarouselDataCount = value;
        }

    },
    state:{
        CarouselData:[],
        CarouselDataCount:0,
        pageSize:8,


    }
}