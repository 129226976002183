import VueRouter from "vue-router";
import {Message} from "element-ui";
//要引入组件
import LoginPage from "@/pages/LoginAndRegister/LoginPage";
import MainPage from "@/pages/MainPage/MainPage";
import CarouselManage from "@/pages/AppPages/CarouselManage.vue";
import NoticeManage from "@/pages/AppPages/NoticeManage.vue";
import NewsManage from "@/pages/AppPages/NewsManage.vue";

import DataDetection from "@/pages/AppPages/DataDetection.vue";


//测试

const index = new VueRouter({
    routes:[
        {
            //规则
            name:"LoginPage",
            path:"/",
            component:LoginPage,

        },
        {
            name: "MainPage",
            path: "/MainPage",
            component: MainPage,
            redirect:"/MainPage/CarouselManage",
            children:[
                {
                    name:"CarouselManage",
                    path:"CarouselManage",
                    component:CarouselManage,
                    meta:{
                        isAuth:true,
                    },
                },
                {
                    name:"NewsManage",
                    path:"NewsManage",
                    component:NewsManage,
                    meta:{
                        isAuth:true,
                    },
                },
                {
                    name:"DataDetection",
                    path:"DataDetection",
                    component:DataDetection,
                    meta:{
                        isAuth:true,
                    },
                },
                {
                    name:"NoticeManage",
                    path:"NoticeManage",
                    component:NoticeManage,
                    meta:{
                        isAuth:true,
                    },
                },
            ]

        }

    ]
});
index.beforeEach((to, from, next) => {
    // console.log(sessionStorage.getItem('user_id'))

    if(to.meta.isAuth === true){
        if(sessionStorage.getItem('user_id') === null || sessionStorage.getItem('RefreshToken') === null){
            Message({
                message:"请登录!",
                type:"warning"
            })
            next({name:"LoginPage"});
        }else{
            next();
        }
    }else{
        next();
    }
})


export default index