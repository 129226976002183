<template>
  <el-container>
    <router-view></router-view>
  </el-container>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style >
  html,
  body,
  #app,
  .el-container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: whitesmoke; /* 设置页面背景颜色为暗色调 */
  }
  h1,h2,h3,h4,h5,h6,p,a{
    margin: 0;
    padding: 0;

  }
</style>
