<template>
    <div id="ToolID" class="row row-cols-6">
        <div style="width: 200px">
            <slot name="Tool1"></slot>
        </div>
        <div>
            <slot name="Tool2"></slot>
        </div>
        <div>
            <slot name="Tool3"></slot>
        </div>
        <div>
            <slot name="Tool4"></slot>
        </div>
        <div>
            <slot name="Tool5"></slot>
        </div>
        <div>
            <slot name="Tool6"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ToolBar"
    }
</script>

<style scoped lang="scss">
    #ToolID{
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin: 0;
        padding: 0;
        background-color: #fff;

    }
</style>