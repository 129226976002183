import NetworkProcess from "@/ToolAPI/NetworkProcess";
import router from '../../router'
import {Message} from "element-ui";
export default {
    namespaced:true,
    actions:{
        AdminLogin(context,formData){
            NetworkProcess.Request_Post("/AdminLogin",formData,{},(res)=>{
                console.log(res.headers);
                console.log(res.headers.authorization);
                if(res.data.statusCode === 200 && !(res.headers.authorization === undefined)){
                    //登陆成功
                    sessionStorage.setItem("RefreshToken",JSON.parse(res.headers.authorization).RefreshToken);
                    sessionStorage.setItem("AccessToken",JSON.parse(res.headers.authorization).AccessToken);
                    sessionStorage.setItem("user_id",res.data.data.user_id);
                    sessionStorage.setItem("username",res.data.data.username);
                    Message({
                        message:"登录成功!",
                        type:"success"
                    })
                    router.replace({
                        name:"MainPage"
                    }).catch(err=>err);
                }else{
                    Message({
                        message:"登录异常!",
                        type:"warning"
                    })
                }
            })
        }



    },
    mutations:{
        AdminDataUpdate(state,value){
            state.admin_name = value;
        }
    },
    state:{
        admin_name:"",
    }



}