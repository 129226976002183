<template>
    <div id="bigID">
        <div class="container">
            <h2>后台管理</h2>
            <form id="loginForm" action="javascript:">
                <div class="form-group">
                    <label for="username">Username:</label>
                    <input v-model="LoginMessages.username" type="text" id="username" name="username" required>
                </div>
                <div class="form-group">
                    <label for="password">Password:</label>
                    <input v-model="LoginMessages.password" type="password" id="password" name="password" required>
                </div>
                <div class="form-group">
                    <button type="submit" @click="clickToLogin">登录</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: "LoginPage",
        mounted(){
          sessionStorage.setItem("RefreshToken",null);
          sessionStorage.setItem("AccessToken",null);
          sessionStorage.setItem("user_id",null);
          sessionStorage.setItem("username",null);
        },
        data(){
            return {
                LoginMessages:{
                    username:"",
                    password:"",
                }
            }
        },
        methods:{
            clickToLogin(){
                let formData = new FormData();
                formData.append("username",this.LoginMessages.username);
                formData.append("password",this.LoginMessages.password);
                this.AdminLogin(formData);
            },
            ...mapActions("LoginDatas",['AdminLogin']),
        }
    }
</script>

<style scoped lang="scss">
    body {
      font-family: Arial, sans-serif;
      background-color: #fff;

    }
    #bigID{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("@/assets/BGI.jpg");
      background-size: 100% 100%;
    }
    .container {
        opacity: 0.95;
        max-width: 400px;
        margin: 100px auto;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    .form-group {
        margin-bottom: 15px;
    }
    .form-group label {
        display: block;
        margin-bottom: 5px;
    }
    .form-group input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 3px;
    }
    .form-group button {
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
    }
</style>