/**
*   author:胡作非为还响桃
*   Date:2024/7/30
*   FunctionDesc:""
*/
<template>
  <div id="NewsManageRoot">
    <ToolBar>
      <template v-slot:Tool1>
        <h3 style="text-align: center;line-height: 50px;font-weight: bold">新闻管理</h3>
      </template>
    </ToolBar>
    <div class="d-flex">
      <form id="NewsSearch" onsubmit="" class="row">
        <div class="col-md-4">
          <div class="input-group">
            <input class="form-control" type="text" placeholder="请输入新闻标题..." v-model="queryNewsTitle"/>
            <button class="btn btn-primary" @click="SearchConfirm">搜索</button>
          </div>
        </div>
      </form>
      <div class="ToolBarItems">
        <el-tooltip class="item" effect="dark" content="页面仅展示最新的5条新闻" placement="top-start">
          <el-button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#AddNewsModal">添加新闻</el-button>
        </el-tooltip>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="AddNewsModal" tabindex="-1" aria-labelledby="AddNewsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="AddNewsModalLabel">添加新闻</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form id="NewsAdd">
                <div >
                  <label class="form-label">新闻标题:</label>
                  <input class="form-control" v-model="InsertNewsData.news_title" type="text"/>
                </div>
                <div>
                  <label class="form-label">新闻内容:</label>
                  <textarea class="form-control" v-model="InsertNewsData.news_content" placeholder="新闻内容..."></textarea>
                </div>
                <div>
                  <label class="form-label">新闻地址:</label>
                  <textarea class="form-control" v-model="InsertNewsData.news_url" placeholder="新闻地址..."></textarea>
                </div>
                <div>
                  <label class="form-label">新闻图片:</label>
                  <input id="news_img1" class="form-control" type="file" placeholder="请上传图片..."/>
                </div>

              </form>
            </div>
            <div class="modal-footer">
              <button type="button" @click="InsertCancel" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="button" @click="InsertConfirm" class="btn btn-primary">确定修改</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-table
        :data="NewsData"
        style="width: 100%">
      <el-table-column
          fixed
          prop="id"
          label="id"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="news_title"
          label="新闻标题"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="news_content"
          label="新闻内容"
          width="300">
      </el-table-column>
      <el-table-column
          prop="news_url"
          label="新闻地址"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="news_img_path"
          label="新闻图片路径"
          width="auto">
      </el-table-column>
      <el-table-column
          prop="news_img_url"
          label="新闻图片访问URL"
          width="auto">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="170">
        <template slot-scope="scope">
          <el-button
              @click="LoadSelectedNews(scope.$index)"
              type="text"
              size="small"
              data-bs-toggle="modal" data-bs-target="#AlterNewsModal">
            修改
          </el-button>

          <el-button
              @click="deleteNews(scope.$index)"
              type="text"
              size="small">
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Modal -->
    <div class="modal fade" id="AlterNewsModal" tabindex="-1" aria-labelledby="AlterNewsModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="AlterNewsModalLabel">修改新闻</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form id="NewsAlter">
              <div >
                <label class="form-label">id:</label>
                <input class="form-control" v-model="SelectedNewsData.id" type="text" disabled="disabled"/>
              </div>
              <div>
                <label class="form-label">新闻标题:</label>
                <input class="form-control" v-model="SelectedNewsData.news_title" type="text" placeholder="请输入新闻标题..."/>
              </div>
              <div>
                <label class="form-label">新闻内容:</label>
                <textarea class="form-control" v-model="SelectedNewsData.news_content" placeholder="新闻内容..."></textarea>
              </div>
              <div>
                <label class="form-label">新闻地址:</label>
                <textarea class="form-control" v-model="SelectedNewsData.news_url" placeholder="新闻地址..."></textarea>
              </div>
              <div>
                <label class="form-label">更改图片文件:</label>
                <input id="news_img2" class="form-control" type="file" placeholder="请上传图片..."/>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" @click="AlterCancel" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" @click="AlterConfirm" class="btn btn-primary">确定修改</button>
          </div>
        </div>
      </div>
    </div>


    <el-pagination
        class="text-center"
        background
        layout="prev, pager, next"
        @prev-click="previousPage"
        @next-click="nextPage"
        @current-change="changePage"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="NewsDataCount">
    </el-pagination>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import ToolBar from "@/components/Header/ToolBar.vue";

export default {
  name: "NewsManage",
  components: {ToolBar},
  mounted(){
    this.changePage();
  },
  computed:{
    ...mapState("NewsData",['NewsData','NewsDataCount','pageSize'])
  },
  data(){
    return {
      currentPage:1,
      queryNewsTitle:"",
      InsertNewsData:{
        news_title:"",
        news_content:"",
        news_url:"",
      },
      SelectedNewsData:{
        id:"",
        news_title:"",
        news_content:"",
        news_url:"",
      }

    }
  },
  methods:{

    previousPage(){
      this.currentPage--;
      this.NewsDataQuery();
    },
    nextPage(){
      this.currentPage++;
      this.NewsDataQuery();
    },
    changePage(page){
      if(page !== undefined){
        this.currentPage = page;
      }
      console.log(this.currentPage)
      this.NewsDataQuery();
    },

    SearchConfirm(){
      this.NewsDataQuery();
    },


    NewsDataQuery(){
      //页面修改时发起下一次查询请求...
      let formData = new FormData();
      formData.append("currentPage",this.currentPage);
      formData.append("pageSize",this.pageSize);
      formData.append("queryNewsTitle",this.queryNewsTitle);
      this.NewsQuery(formData);
    },





    InsertCancel(){
      this.InsertNewsData.news_title = "";
      this.InsertNewsData.news_content = "";
    },
    InsertConfirm(){
      let formData = new FormData();
      formData.append("news_title",this.InsertNewsData.news_title);
      formData.append("news_content",this.InsertNewsData.news_content);
      formData.append("news_url",this.InsertNewsData.news_url);
      let fileObj = document.getElementById('news_img1');
      const file = fileObj.files[0];
      formData.append("news_img",file);
      this.InsertNews(formData);
    },


    AlterCancel(){
      this.SelectedNewsData.id = "";
      this.SelectedNewsData.news_title = "";
      this.SelectedNewsData.news_content = "";
    },
    AlterConfirm() {
      let formData = new FormData();
      formData.append("id",this.SelectedNewsData.id);
      formData.append("news_title",this.SelectedNewsData.news_title);
      formData.append("news_content",this.SelectedNewsData.news_content);
      formData.append("news_url",this.SelectedNewsData.news_url);
      let fileObj = document.getElementById('news_img2');
      const file = fileObj.files[0];
      formData.append("news_img",file);
      this.AlterNews(formData);
    },

    LoadSelectedNews(index){
      this.SelectedNewsData.id = this.NewsData[index]['id'];
      this.SelectedNewsData.news_title = this.NewsData[index]['news_title'];
      this.SelectedNewsData.news_content = this.NewsData[index]['news_content'];
      this.SelectedNewsData.news_url = this.NewsData[index]['news_url'];
    },
    deleteNews(index){
      let formData = new FormData();
      formData.append("id",this.NewsData[index]['id']);
      this.DeleteNews(formData);
    },


    ...mapActions("NewsData",['NewsQuery','DeleteNews','InsertNews','AlterNews']),
  },
  watch:{
    "queryNewsTitle":{
      deep:false,
      immediate:false,
      handler(newData){
        if(newData === ""){
          this.NewsDataQuery();
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  #NewsManageRoot{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;

  }
  #NewsSearch{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
  }
  .ToolBarItems{
    width:500px;
    height: 100%;
    margin: 0;
    padding: 10px;
  }
</style>