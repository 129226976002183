/*
* author:胡作非为还响桃
* date:2024/02/21
*
*   本API参数说明如下：
*       url：接口地址
*       formData：建议使用js的formData对象，API建议统一采用formData的形式进行数据传递。但常规的写法也是支持的。
*       responseFunc：传入一个函数作为回调函数，该函数将接收返回值并执行请求完毕后的相关处理
*
*
*   /*基本信息字段：status（状态码），description（消息内容描述），type（消息类型），data（数据返回）
*   状态码规范：
*       //用户登录/注册相关：100-109
*           100:登录成功、注册成功
*           101:登录失败、注册失败
*           102:
*           103:
*           104:
*           105:
*           106:
*           107:
*           108:
*           109:
*
*       //用户操作相关：200-209
*           200：操作成功、请求成功
*           201：操作失败、请求失败
*           202：token验证成功
*           203：token验证失败
*           204：频繁请求错误,禁止访问!
*           205：操作非法锁定,禁止访问!
*           206：
*           207：
*           208：
*           209：
*       //系统错误503,codeError
* */

import axios from 'axios';
import {Message} from "element-ui";
import router from "@/router";
export default {
    // serverPathPrefix : "http://hu5p.com",
    serverPathPrefix : "http://90kp.com",
    // serverPathPrefix : "",
    //Get
    Request_Get(url,paramStr,responseFunc){
        axios.get(this.serverPathPrefix+url+"?"+paramStr).then(responseFunc).catch(err=>{
            console.log(err);
        });
    },
    //Post
    Request_Post(url,formData,headers,responseFunc){
        axios.post(this.serverPathPrefix+url,formData,{
            headers:headers,
        }).then(responseFunc).catch(err=>err)
    },

    // //常规表单数据传输
    // messageUpload_Post(url,formData,responseFunc){
    //     axios.post(url,formData).then(responseFunc).catch(err=>{
    //         console.log(err);
    //     });
    // },
    // //表单文件传输
    // messageAndFileUpload_Post(url,formData,responseFunc){
    //     if(!(sessionStorage.getItem("RefreshToken")===null || sessionStorage.getItem("RefreshToken")==="" || sessionStorage.getItem("RefreshToken")===undefined)){
    //         axios.post(url,formData,{
    //             headers:{
    //                 //每一个操作都需要验证token令牌，因此请求头携带token
    //                 'Authorization':JSON.stringify({
    //                     "RefreshToken":sessionStorage.getItem("RefreshToken"),
    //                     "AccessToken":sessionStorage.getItem("AccessToken"),
    //                 })
    //                 // 'Content-type' : 'multipart/form-data;charset=UTF-8'
    //             }
    //         }).then(responseFunc).catch(err=>err);
    //     }
    // },
    //
    //
    // //数据库增删改查请求
    // operation_Post(url,formData,responseFunc){
    //     if(!(sessionStorage.getItem("RefreshToken")===null || sessionStorage.getItem("RefreshToken")==="" || sessionStorage.getItem("RefreshToken")===undefined)){
    //         axios.post(url,formData,{
    //             headers:{
    //                 //每一个操作都需要验证token令牌，因此请求头携带token
    //                 'Authorization':JSON.stringify({
    //                     "RefreshToken":sessionStorage.getItem("RefreshToken"),
    //                     "AccessToken":sessionStorage.getItem("AccessToken"),
    //                 })
    //                 // 'Content-type' : 'multipart/form-data;charset=UTF-8'
    //             }
    //         }).then(responseFunc).catch(err=>err);
    //     }
    //
    // },


    //文件下载请求
    fileDownload_Post(url,formData){
        axios.post(this.serverPathPrefix+url,formData,{
            headers:{
                //每一个操作都需要验证token令牌，因此请求头携带token
                'Authorization':sessionStorage.getItem("token"),
            },
            responseType:"blob"

        }).then((res)=>{
            if(res.data.status === 203){
                //token验证失败，重新登录
                Message({
                    message:"身份失效，重新登录。",
                    type:"danger"
                });
                router.replace({
                    name:"LoginPage",
                }).catch(err=>err);
            }
            if(res.data.status === 201){
                Message({
                    message:"文件下载失败",
                    type:"warning"
                });
            }else{
                //以下为触发下载操作的代码---------------------
                const blob = new Blob([res.data]);
                let url = window.URL.createObjectURL(blob);

                //创建一个a标签元素，设置下载属性，点击下载，最后移除该元素
                let link = document.createElement('a');
                link.href = url;
                link.style.display = 'none';
                //res.headers.fileName 取出后台返回下载的文件名;
                // 使用 TextDecoder 解码为字符串
                let encoder = new TextEncoder();
                let decoder = new TextDecoder('utf-8');
                let str1 = encoder.encode(escape(res.headers.filename));//将乱码字符串转换为arraybuffer)

                const downloadFileName = decodeURIComponent(decoder.decode(str1));
                console.log(res.headers);
                link.setAttribute('download',downloadFileName);
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }).catch(err=>err);

    },




};