/**
*   author:胡作非为还响桃
*   Date:2024/8/5
*   FunctionDesc:""
*/
<template>
  <div id="DataDetection">
    <ToolBar>
      <template v-slot:Tool1>
        <h3 style="text-align: center;line-height: 50px;font-weight: bold">网站数据监测</h3>
      </template>
    </ToolBar>
  </div>
</template>

<script>
import ToolBar from "@/components/Header/ToolBar.vue";

export default {
  name: "DataDetection",
  components: {ToolBar}
}
</script>

<style scoped lang="scss">
  #DataDetection{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

  }
</style>